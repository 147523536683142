import { booleanAttribute, ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { toSignal } from '@angular/core/rxjs-interop';

import { TranslateModule } from '@ngx-translate/core';
import { EnzoIconComponent, EnzoTextComponent } from '@enzo';

import { FEATURE_FLAGS_DEFAULTS, ProductAnalyticsService } from '@cosCoreServices/product-analytics/product-analytics.service';

@Component({
  standalone: true,
  selector: 'app-premium-seller-badge',
  templateUrl: './premium-seller-badge.component.html',
  styleUrls: ['./premium-seller-badge.component.scss'],
  imports: [EnzoIconComponent, EnzoTextComponent, TranslateModule, NgOptimizedImage],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { '[class.compact]': 'compact()' },
})
export class PremiumSellerBadgeComponent {
  public sellerUuid = input.required<string>();
  public compact = input(false, { transform: booleanAttribute });

  public premiumSellerFeatureFlag = toSignal(
    inject(ProductAnalyticsService).getFeatureFlag('eg-premium-seller-badge', FEATURE_FLAGS_DEFAULTS['eg-premium-seller-badge']),
    { initialValue: FEATURE_FLAGS_DEFAULTS['eg-premium-seller-badge'] },
  );
  public displayBadge = computed(() => this.premiumSellerFeatureFlag().payload.premiumSellersUuids.includes(this.sellerUuid()));
}
